import React from 'react';
import * as styles from '../Footer/css/style.module.scss';
import logoImg from '../Footer/img/logo.svg';

export const FooterLink = () => {
    return <footer className={styles.footer}>
        <div className={styles.main}>
            <div className={styles.logo}>
                <img src={logoImg} alt=""/>
            </div>

            <div className={styles.contentsWrap}>
                <div className={styles.contents}>
                    <a href={'/?pos=top'}>TOP</a>
                    <a href={'/?pos=mission'}>OUR MISSION</a>
                    <a href={'/?pos=service'}>SERVICE</a>
                    <a href={'/?pos=team'}>TEAM</a>
                </div>

                <div className={styles.contents}>
                    <a href={'/?pos=recruiting'}>RECRUITING</a>
                    <a href={'/?pos=news'}>NEWS</a>
                    <a href={'/?pos=company'}>COMPANY</a>
                    <a href={'/?pos=contact'}>CONTACT</a>
                </div>
            </div>
        </div>

        <div className={styles.copy}>
            Copyright © {`${new Date().getFullYear()}`} mento Inc. All rights reserved.
        </div>
    </footer>
}
